import {
  DATE_FORMAT,
  EntityMapModel,
  GRID_ACTIONS,
  IAPIGridRequest,
  IOptionValue,
  SEARCH_ENTITY_TYPE,
  UIStore,
  Utilities,
  baseEntitySearchFilters,
} from '@wings-shared/core';
import { AuditFields, EDITOR_TYPES, ViewInputControlsGroup, IGroupInputControls } from '@wings-shared/form-controls';
import { useUnsubscribe } from '@wings-shared/hooks';
import { DetailsEditorHeaderSection, DetailsEditorWrapper } from '@wings-shared/layout';
import { EntityOptionsStore, ModelStatusOptions, useBaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { inject, observer } from 'mobx-react';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { finalize, takeUntil } from 'rxjs/operators';
import { BulletinModel } from '../Models';
import { BulletinStore } from '../Stores/Bulletin.store';
import { fields } from './Fields';
import { useStyles } from './UpsertBulletin.styles';

interface Props {
  bulletinStore?: BulletinStore;
  securityModule: any;
  basePath: string;
}

const UpsertBulletin: FC<Props> = ({ bulletinStore, basePath, securityModule }: Props) => {
  const [ selectedBulletin, setSelectedBulletin ] = useState(new BulletinModel());

  const [ bulletinEntityOptions, setBulletinEntityOptions ] = useState([]);
  const params = useParams();
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<BulletinModel>(params, fields, baseEntitySearchFilters);
  const navigate = useNavigate();
  const classes = useStyles();
  const entityOptionsStore = new EntityOptionsStore();
  const backNavLink = `${basePath}/bulletins`;

  const hasBulletinLevel = Boolean(useUpsert.getField('bulletinLevel').value?.name);

  useEffect(() => {
    useUpsert.setViewMode((params.viewMode.toUpperCase() as VIEW_MODE) || VIEW_MODE.DETAILS);
    loadInitialData();
  }, []);

  /* istanbul ignore next */
  const loadInitialData = (): void => {
    if (!params.bulletinId) {
      return;
    }
    UIStore.setPageLoader(true);
    bulletinStore
      ?.getBulletinById(parseInt(params.bulletinId))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        setSelectedBulletin(response);
        useUpsert.setFormValues(response);
        useUpsert.setFormRules('endDate', !response.isUFN);
      });
  };

  // Save Bulletin data
  const upsertBulletin = (): void => {
    const request = new BulletinModel({ ...selectedBulletin, ...useUpsert.form.values() });
    UIStore.setPageLoader(true);
    bulletinStore
      ?.upsertBulletin(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: BulletinModel) => {
          if (Utilities.isEqual(params.viewMode, VIEW_MODE.DETAILS)) {
            setSelectedBulletin(response);
            useUpsert.setViewMode(VIEW_MODE.DETAILS);
            return;
          }
          navigate(backNavLink);
        },
        error: error => {
          useUpsert.showAlert(error.message, 'upsertBulletin');
        },
      });
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'bulletinLevel':
        useUpsert.getField('bulletinEntity').clear();
        break;
      case 'isUFN':
        useUpsert.setFormRules('endDate', !value);
        useUpsert.getField('endDate').validate();
        break;
      default:
        useUpsert.getField(fieldKey).set(value);
    }
  };

  // Search Entity based on field value
  const onSearch = (searchValue: string, fieldKey: string): void => {
    const bulletinLevel: SEARCH_ENTITY_TYPE = useUpsert.getField('bulletinLevel').value?.name;
    const request: IAPIGridRequest = useUpsert.getSearchRequest(searchValue, bulletinLevel as SEARCH_ENTITY_TYPE);
    useUpsert.loader.showLoader();
    entityOptionsStore
      .searchEntity(bulletinLevel, request, searchValue)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => useUpsert.loader.hideLoader())
      )
      .subscribe(response => setBulletinEntityOptions(response));
  };

  const onAction = (action: GRID_ACTIONS): void => {
    switch (action) {
      case GRID_ACTIONS.SAVE:
        upsertBulletin();
        break;
      case GRID_ACTIONS.EDIT:
        useUpsert.setViewMode(VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        if (Utilities.isEqual(params.viewMode, VIEW_MODE.DETAILS)) {
          useUpsert.form.reset();
          useUpsert.setFormValues(selectedBulletin);
          useUpsert.setViewMode(VIEW_MODE.DETAILS);
          return;
        }
        navigate(backNavLink);
        break;
    }
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'bulletinLevel':
        useUpsert.observeSearch(bulletinStore?.getBulletinLevels());
        setBulletinEntityOptions([]);
        break;
      case 'appliedBulletinTypes':
        useUpsert.observeSearch(bulletinStore?.getBulletinTypes());
        break;
      case 'bulletinPriority':
        useUpsert.observeSearch(bulletinStore?.getBulletinPriorities());
        break;
      case 'uaOffice':
        useUpsert.observeSearch(bulletinStore?.loadUAOffices());
        break;
      case 'bulletinSource':
        useUpsert.observeSearch(bulletinStore?.getSources());
        break;
      case 'accessLevel':
        useUpsert.observeSearch(bulletinStore?.getAccessLevels());
        break;
    }
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'bulletinLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: bulletinStore.bulletinLevels,
            isDisabled: useUpsert.isEditView,
          },
          {
            fieldKey: 'bulletinEntity',
            type: EDITOR_TYPES.DROPDOWN,
            isLoading: useUpsert.loader.isLoading,
            getOptionLabel: option => (option as EntityMapModel)?.label,
            options: bulletinEntityOptions,
            isDisabled: !hasBulletinLevel || useUpsert.isEditView,
          },
          {
            fieldKey: 'appliedBulletinTypes',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            options: bulletinStore.bulletinTypes,
          },
          {
            fieldKey: 'bulletinPriority',
            type: EDITOR_TYPES.DROPDOWN,
            options: bulletinStore.bulletinPriorities,
          },
          {
            fieldKey: 'startDate',
            type: EDITOR_TYPES.DATE,
            dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
            maxDate: useUpsert.getField('endDate').value,
          },
          {
            fieldKey: 'endDate',
            type: EDITOR_TYPES.DATE,
            dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
            minDate: useUpsert.getField('startDate').value,
          },
          {
            fieldKey: 'isUFN',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'notamNumber',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'uaOffice',
            type: EDITOR_TYPES.DROPDOWN,
            options: bulletinStore.uaOffices,
          },
          {
            fieldKey: 'vendorName',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'runTripChecker',
            type: EDITOR_TYPES.CHECKBOX,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'internalNotes',
            type: EDITOR_TYPES.TEXT_FIELD,
            multiline: true,
            isFullFlex: true,
            rows: 3,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'sourceNotes',
            type: EDITOR_TYPES.TEXT_FIELD,
            multiline: true,
            isFullFlex: true,
            rows: 3,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'dmNotes',
            type: EDITOR_TYPES.TEXT_FIELD,
            multiline: true,
            isFullFlex: true,
            rows: 3,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'bulletinText',
            type: EDITOR_TYPES.TEXT_FIELD,
            multiline: true,
            isFullFlex: true,
            rows: 3,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'bulletinSource',
            type: EDITOR_TYPES.DROPDOWN,
            options: bulletinStore.sources,
          },
          {
            fieldKey: 'accessLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: bulletinStore.accessLevels,
          },
          {
            fieldKey: 'status',
            type: EDITOR_TYPES.DROPDOWN,
            options: ModelStatusOptions,
          },
        ],
      },
    ];
  };

  const headerActions = (): ReactNode => {
    return (
      <DetailsEditorHeaderSection
        title="Bulletin"
        backNavTitle="Bulletin"
        disableActions={useUpsert.form.hasError || UIStore.pageLoading || !useUpsert.form.changed}
        backNavLink={backNavLink}
        isEditMode={useUpsert.isEditable}
        hasEditPermission={securityModule.isEditable}
        onAction={action => onAction(action)}
      />
    );
  };

  return (
    <DetailsEditorWrapper
      headerActions={headerActions()}
      isEditMode={useUpsert.isEditable}
      classes={{ container: classes.editorWrapperContainer, headerActionsEditMode: classes.headerActionsEditMode }}
    >
      <ViewInputControlsGroup
        groupInputControls={groupInputControls()}
        field={fieldKey => useUpsert.getField(fieldKey)}
        isEditing={useUpsert.isEditable}
        isLoading={useUpsert.loader.isLoading}
        onValueChange={(option: IOptionValue, fieldKey: string) => onValueChange(option, fieldKey)}
        onFocus={(fieldKey: string) => onFocus(fieldKey)}
        onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
      />
      <AuditFields
        isNew={useUpsert.isAddNew}
        isEditable={useUpsert.isEditable}
        fieldControls={useUpsert.auditFields}
        onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
      />
    </DetailsEditorWrapper>
  );
};

export default inject('bulletinStore')(observer(UpsertBulletin));
